import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasLicence } from "../../../interfaces/interface.licence"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"
import { type___params__url } from "../../../types/types.params"
import { type___request___order } from "../../../types/types.types"

//	P R O P S

export interface props___SbVehicleViewPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence
{
	params: type___params__url,
}

//	S T A T E

export interface state___SbVehicleViewPage {

	selected___vehicle_view___tab: 'gallery' | 'datas' | 'specs' | 'leads' | 'status' | 'documents',

	vehicle___id: string,
	vehicle___props: any | null,

	vehicle___leads___list: any[],
	vehicle___leads___list___order: type___request___order,

	vehicle___documents: any[],
	vehicle___documents___is_uploading: boolean,

	vehicle___media___is_sync: boolean,






	GUI___page___is_loading___section: boolean,
	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbVehicleViewPage___defaults: state___SbVehicleViewPage = {

	selected___vehicle_view___tab: 'gallery',

	vehicle___id: '',
	vehicle___props: null,

	vehicle___leads___list: [],
	vehicle___leads___list___order: { param: 'createdAt', direction: -1 },

	vehicle___documents: [],
	vehicle___documents___is_uploading: false,

	vehicle___media___is_sync: false,




	





	GUI___page___is_loading___section: true,
	GUI___page___is_loading: true,

}