import React from "react";
import { Link } from "react-router-dom";
import { AutorotatePlugin, ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

//	S T Y L E

import "./sb-vehicles-view.scss";

//	L I B S

import { lib_names } from "../../../libs/lib.names";
import { lib_vehicle_attributes } from "../../../libs/lib.attributes";

//	F U N C S

import { funcs_datetime } from "../../../funcs/funcs.datetime";
import { funcs_numbers } from "../../../funcs/funcs.numbers";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___lead___status, type___request___order, type___vehicle___price_vat_type, type___vehicle___type } from "../../../types/types.types";
import { type___params__url } from "../../../types/types.params";
import { type___toast___controller } from "../../../types/types.toasts";

//	S T A T E S

import { props___SbVehicleViewPage, state___SbVehicleViewPage, state___SbVehicleViewPage___defaults } from "./sb-vehicles-view.state";

//	C O M P O N E N T S

import Com404Component from "../../../components/com-404/com-404";
import ComLicenceInactiveComponent from "../../../components/com-licence-inactive/com-licence-inactive";
import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";
import ComSlideShowComponent from "../../../components/com-slideshow/com-slideshow-results";
import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";

//	M O D A L S

import MdDocumentUploaderModal from "../../../modals/md-document-upload/md-document-uploader";
import MdVehicleResyncMediaModal from "../../../modals/md-vehicle-resync-media/md-vehicle-resync-media";

//	C L A S S

export default class SbVehicleViewPage extends React.Component<props___SbVehicleViewPage, state___SbVehicleViewPage>
{

//#region 																							D E C L A R A T I O N S

	readonly _URL_PARAMS: type___params__url;

	private readonly _API: service_RestApiService = new service_RestApiService();
	
	private readonly lib___vehicle_attributes: lib_vehicle_attributes = new lib_vehicle_attributes();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbVehicleViewPage,
	)
	{
		super(_PROPS);
		this.state = state___SbVehicleViewPage___defaults;
		this._URL_PARAMS = this.props.params;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___vehicle_attributes = async () => {
		this.setState({
			GUI___page___is_loading: true,
		}, async () => { 
			const ___vehicle_attributes___found: type___api___response = await this._API.vehicles___read___single(this._URL_PARAMS.id);
			if (___vehicle_attributes___found.response === 'success')
			{
				this.setState({
					vehicle___props: ___vehicle_attributes___found.data,
					GUI___page___is_loading: false
				});
			}
			else
			{
				const ___alert_props : type___alert___controller = {
					alert___title: "Errore",
					alert___message : <>Impossibile caricare questo Veicolo...</>,
					alert___buttons : [
						{
							alert___button___icon: 'fa-chevron-left',
							alert___button___text: 'Torna Indietro',
							alert___button___action : () => {
								this._PROPS.alert___setShowing(false);
								if (window.history.length > 1) { window.history.back(); }
								else { window.location.href = '/vehicles/list'; }
							}
						}
					]
				}
				this._PROPS.alert___setAlert(___alert_props);
				this._PROPS.alert___setShowing(true);
			}
		});
	}

	read_props___vehicle___leads_list = async (___is_update: boolean = true) => {
		this.setState({
			GUI___page___is_loading___section: (___is_update === true) ? true : this.state.GUI___page___is_loading___section, 
			vehicle___leads___list: (___is_update === true) ? [] : this.state.vehicle___leads___list
		}, async () => { 
			const ___leads___founds: type___api___response = await this._API.leads___read___multi('vehicle', this.state.vehicle___id, this.state.vehicle___leads___list.length, this.state.vehicle___leads___list___order);
			this.setState({
				vehicle___leads___list: ___leads___founds.data,
				GUI___page___is_loading___section: false
			});
		});
	}

	read_props___vehicle___documents = async () => {
		const ___documents___founds: type___api___response = await this._API.documents___read___multi('vehicle', this.state.vehicle___id);
		this.setState({
			vehicle___documents: ___documents___founds.data
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbVehicleViewPage['selected___vehicle_view___tab']) => {
		this.setState({
			GUI___page___is_loading___section: true,
			selected___vehicle_view___tab: ___e,
		}, async () => {
			switch (___e) {
				case 'gallery':
					this.setState({
						GUI___page___is_loading___section: true,
					});
					break;
				case 'leads':
					this.setState({
						GUI___page___is_loading___section: true,
					}, async () => {
						await this.read_props___vehicle___leads_list();
					});
					break;
				case 'status':
					break;
			}
		});
	}

	handle___vehicle___sell = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma vendita",
			alert___message : <>Vuoi rendere <b>VENDUTO</b> il veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>" senza abbinare un Lead?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, vendi',
					alert___button___action: async () => {
						const ___sold___vehicle: type___api___response = await this._API.vehicles___update_sell(this.state.vehicle___props['vehicle_id'], 'sell');
						if (___sold___vehicle['response'] === 'success')
						{
							await this.read_props___vehicle_attributes();
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai venduto il veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nella vendita del veicolo</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___vehicle___unsell = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma annullamento vendita",
			alert___message : <>Vuoi <b>ANNULLARE LA VENDITA</b> per il veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, annulla vendita',
					alert___button___action: async () => {
						const ___unsold___vehicle: type___api___response = await this._API.vehicles___update_sell(this.state.vehicle___props['vehicle_id'], 'unsell');
						if (___unsold___vehicle['response'] === 'success')
						{
							await this.read_props___vehicle_attributes();
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai annullato la vendita del veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'annullamento della vendita</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___vehicle___sync_source = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Sincronizzazione da sorgente",
			alert___message : <>Vuoi procedere alla sincronizzazione da sorgente remota per il veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"?<br/><br/>Utilizza questa funzione se hai aggiornato le informazioni dalla sorgente remota e vuoi sincronizzarle con il gestionale</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-sync',
					alert___button___text: 'Sincronizza',
					alert___button___action: () => {
						this.setState({
							GUI___page___is_loading: true,
						}, async () => {
							const ___resync___vehicle: any = await this._API.sync_vehicles___from___subito_it___single(this.state.vehicle___props['vehicle_id']);
							console.log('___resync___vehicle')
							this._PROPS.alert___setShowing(false);







/*











							if (___unsold___vehicle['is_updated'] === true)
								{
									await this.read_props___vehicle_attributes();
									this._PROPS.alert___setShowing(false);
									const ___toast_props: type___toast___controller = {
										toast___color: 'success',
										toast___text: <>Hai annullato la vendita del veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"</>
									}
									this._PROPS.toast___setContent(___toast_props);
									this._PROPS.toast___setShowing(true);
								}
								else
								{
									const ___toast_props: type___toast___controller = {
										toast___color: 'danger',
										toast___text: <>Errore generico nell'annullamento della vendita</>
									}
									this._PROPS.toast___setContent(___toast_props);
									this._PROPS.toast___setShowing(true);
								}


						*/


						});


					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);











	}

	handle___vehicle___props___delete = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma eliminazione",
			alert___message : <>Vuoi <b>ELIMINARE</b> il veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"?<br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						const ___removed_vehicle: type___api___response = await this._API.vehicles___delete(this.state.vehicle___props['vehicle_id']);
						if (___removed_vehicle['response'] === 'success')
						{
							window.location.href = '/vehicles/list';
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai eliminato il veicolo "<b>{ this.state.vehicle___props['vehicle_name'] }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'eliminazione del veicolo</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___leads___sortBy = (___param: string) => {

		let ___new___direction: type___request___order['direction'];
		const ___old___param: string = this.state.vehicle___leads___list___order['param'];
		if (___old___param === ___param) {
			___new___direction = (this.state.vehicle___leads___list___order['direction'] === 1) ? -1 : 1;
		} else {
			___new___direction = 1;
		}

		this.setState({
			vehicle___leads___list___order: {
				param: ___param,
				direction: ___new___direction
			}
		}, async () => {
			await this.read_props___vehicle___leads_list(true);
		});

	}

	handle___file_upload = () => {
		this.setState({
			vehicle___documents___is_uploading: true
		});
	}

	handle___file_upload___done = (___is_done: boolean | null) => {
		switch (___is_done) {
			case true:
				const ___toast_props___true: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Documento caricato con successo</>
				}
				this._PROPS.toast___setContent(___toast_props___true);
				this._PROPS.toast___setShowing(true);
				break;
			case false:
				const ___toast_props___false: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Errore generico nel caricamento di questo documento...</>
				}
				this._PROPS.toast___setContent(___toast_props___false);
				this._PROPS.toast___setShowing(true);
				break;
		}
		this.setState({
			vehicle___documents___is_uploading: false
		}, async () => {
			await this.read_props___vehicle___documents();
		});
	}

	handle___documents___delete = (___document: any, ___index: number) => {
		const ___alert_props : type___alert___controller = {
			alert___title: 'Conferma Eliminazione',		
			alert___message : <><>Vuoi davvero <b>ELIMINARE</b> il documento <b>{ ___document['document_name'] }</b>?</><br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				}, {
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						this._PROPS.alert___setShowing(false);
						const ___removed_vehicle: type___api___response = await this._API.documents___delete(___document['document_id']);
						if (___removed_vehicle['response'] === 'success')
						{
							await this.read_props___vehicle___documents();
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Documento eliminato</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'eliminazione del documento</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___vehicle___resync_media = () => {
		this.setState({
			vehicle___media___is_sync: true
		});
	}

	handle___vehicle___resync_media___done = (___is_done: boolean | null) => {
		switch (___is_done) {
			case true:
				const ___toast_props___true: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Media sicronizzati con successo</>
				}
				this._PROPS.toast___setContent(___toast_props___true);
				this._PROPS.toast___setShowing(true);
				break;
			case false:
				const ___toast_props___false: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Errore generico nella sincronizzazione dei media di questo veicolo...</>
				}
				this._PROPS.toast___setContent(___toast_props___false);
				this._PROPS.toast___setShowing(true);
				break;
		}
		this.setState({
			vehicle___media___is_sync: false
		}, async () => {
			await this.read_props___vehicle___documents();
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		if (this._URL_PARAMS.id)
		{
			this.setState({
				vehicle___id: this._URL_PARAMS.id
			}, async () => {
				await this.read_props___vehicle_attributes();
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render()
	{
		return <>
			{(this.state.GUI___page___is_loading === false) ? <>
				{(this.state.vehicle___props !== null) ? <>
					
					{(this.state.vehicle___documents___is_uploading === true) ? <>
						<MdDocumentUploaderModal target="vehicle" target_param={ this.state.vehicle___id } event___onDismiss={ this.handle___file_upload___done }/>
					</> : <></>}
					{(this.state.vehicle___media___is_sync === true) ? <>
						<MdVehicleResyncMediaModal target_vehicle_id={ this.state.vehicle___id } event___onDismiss={ this.handle___vehicle___resync_media___done }/>
					</> : <></>}

					<div className="page---container is-full-width">
						<section>
							<div className="is-card vehicle-view-single-container---top-bar">
								{(this.state.vehicle___props['vehicle_gallery_pictures'].length > 0) ? <>
									<img src={ lib_names.cdn.endpoint + this.state.vehicle___props['vehicle_gallery_pictures'][0] } alt="{{image}}" />
								</> : <>
									<img src={ lib_names.media.placeholders.vehicle.generic } alt="{{image}}" />
								</>}
								<span>
									<span>{ this.state.vehicle___props['vehicle_name'] }</span>
									<span>{ this.funcs___numbers.format___price(this.state.vehicle___props['vehicle_accounting___price_sell']) }</span>
								</span>
							</div>
							{(this.state.vehicle___props['vehicle_status_sold'] === true) ? <>
								<div className="page---top-bar---vehicle---is-sold">
									<section>
										<>Veicolo <span>venduto</span> il </>
										<span>{ this.funcs___datetime.datetime___get___date(this.state.vehicle___props['vehicle_status_sold_datetime']) }</span>
										<> alle ore </>
										<span>{ this.funcs___datetime.datetime___get___time(this.state.vehicle___props['vehicle_status_sold_datetime']) }</span>
									</section>
									<section>
										{(this.state.vehicle___props['vehicle_status_sold_lead'] && this.state.vehicle___props['vehicle_status_sold_lead'] !== null) ? <>
											<Link to={ '/leads/view/' + this.state.vehicle___props['vehicle_status_sold_lead'] }>
												<span>vai al lead</span>
												<i className="fas fa-chevron-right"></i>
											</Link>
										</> : <>
											<p title="">
												<>Nessun Lead presente in archivio per questa vendita</>
											</p>
										</>}
									</section>
								</div>
							</> : <></>}
							<div className="vehicle-overview---flexy" style={(this.state.vehicle___props['vehicle_status_sold'] === true) ? {height: 'calc(100% - 50pt - 10pt - var(--topbar-optionbar-h) - 10pt)'} : {}}>
								<div>
									<div className="page---top-bar page---top-bar---is-sidebar">
										<section>
											<input type="radio" id="d-ts---csec---gallery" name="d-ts---cb" value="gallery" checked={ this.state.selected___vehicle_view___tab === 'gallery' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
											<label htmlFor="d-ts---csec---gallery" className="is-card">
												<img src="/assets/ui/icons/icon-vehicle-camera.svg" />
												<span>Galleria</span>
											</label>
											<input type="radio" id="d-ts---csec---datas" name="d-ts---cb" value="datas" checked={ this.state.selected___vehicle_view___tab === 'datas' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
											<label htmlFor="d-ts---csec---datas" className="is-card">
												<img src="/assets/ui/icons/icon-vehicle-info.svg" />
												<span>Dati</span>
											</label>
											<input type="radio" id="d-ts---csec---specs" name="d-ts---cb" value="specs" checked={ this.state.selected___vehicle_view___tab === 'specs' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
											<label htmlFor="d-ts---csec---specs" className="is-card">
												<img src="/assets/ui/icons/icon-overview.svg" />
												<span>Specifiche</span>
											</label>
										{/*
											<input type="radio" id="d-ts---csec---status" name="d-ts---cb" value="status" checked={ this.state.selected___vehicle_view___tab === 'status' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
											<label htmlFor="d-ts---csec---status" className="is-card">
												<img src="/assets/ui/icons/icon-conditions.svg" />
												<span>Stato</span>
											</label>
										*/}
											<input type="radio" id="d-ts---csec---leads" name="d-ts---cb" value="leads" checked={ this.state.selected___vehicle_view___tab === 'leads' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
											<label htmlFor="d-ts---csec---leads" className="is-card">
												<img src="/assets/ui/icons/icon-car-match.svg" />
												<span>Leads</span>
											</label>
											{(this.props.props___licence['licence_active___cloudDocs'] === true) ? <>
												<input type="radio" id="d-ts---csec---documents" name="d-ts---cb" value="documents" checked={this.state.selected___vehicle_view___tab === 'documents'} onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
												<label htmlFor="d-ts---csec---documents" className="is-card">
													<img src="/assets/ui/icons/icon-cloud.svg" />
													<span>Cloud Docs</span>
												</label>
											</> : <></>}
										</section>
										<section>
											{(this.state.vehicle___props['vehicle_status_sold'] !== true) ? <>
												<button className="is-card" onClick={() => { this.handle___vehicle___sell(); }}>
													<i className="fas fa-magic"></i>
													<span>Segna come venduto</span>
												</button>
												<Link to={ '/vehicles/edit/' + this.state.vehicle___props['vehicle_id'] } className="is-card">
													<i className="fas fa-edit"></i>
													<span>Modifica Veicolo</span>
												</Link>
												{(this.props.props___licence['licence_active___sync___vehicles'] === true) ? <>
													<button className="is-card" onClick={() => { this.handle___vehicle___sync_source(); }}>
														<i className="fas fa-sync"></i>
														<span>Sincronizza src.</span>
													</button>
													<button className="is-card" onClick={() => { this.handle___vehicle___resync_media(); }}>
														<i className="fas fa-file-import"></i>
														<span>Importa Media</span>
													</button>
												</> : <></>}
												<button className="is-card" onClick={() => { this.handle___vehicle___props___delete(); }}>
													<i className="fas fa-trash"></i>
													<span>Elimina Veicolo</span>
												</button>
											</> : <>
												{(this.state.vehicle___props['vehicle_status_sold'] === true && this.state.vehicle___props['vehicle_status_sold_lead'] === null) ? <>
													<button className="is-card is-cancel" onClick={() => { this.handle___vehicle___unsell(); }}>
														<i className="far fa-window-close"></i>
														<span>Annulla Vendita</span>
													</button>
												</> : <></>}
											</>}
										</section>
									</div>
								</div>

								{(() => {
									switch (this.state.selected___vehicle_view___tab) {
										case 'gallery': return <>
											<div className="is-card vehicle-gallery---container">
												<ComSlideShowComponent images={ this.state.vehicle___props['vehicle_gallery_pictures'] }/>
											</div>
										</>; break;
										case 'datas': return <>
											<div className="container---table table-vehicle---registry is-card">
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Veicolo</div><div></div></div>
												<div>
													<div></div>
													<div>Veicolo</div>
													<div>{ this.state.vehicle___props['vehicle_name'] }</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Dati</div><div></div></div>
												<div>
													<div></div>
													<div>Tipologia</div>
													<div>
														{(() => {
															switch (this.state.vehicle___props['vehicle_category'] as type___vehicle___type) {
																case 'car': return <span>Automobile</span>; break;
																case 'commercial': return <span>Veicolo Commerciale</span>; break;
																case 'motorcycle': return <span>Motociclo / Ciclomotore</span>; break;
															}
														})()}
													</div>
												</div>
												<div>
													<div></div>
													<div>Categoria</div>
													<div>
														{(this.state.vehicle___props['vehicle_conditions___category']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('vehicle_conditions___category', this.state.vehicle___props['vehicle_conditions___category'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Km Percorsi</div>
													<div>{ this.funcs___numbers.format___mileage(this.state.vehicle___props['vehicle_conditions___km']) + ' km' }</div>
												</div>
												<div>
													<div></div>
													<div>Precedenti Proprietari</div>
													<div>{ this.state.vehicle___props['vehicle_conditions___owners_number'] }</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Registrazione</div><div></div></div>
												<div>
													<div></div>
													<div>Immatricolazione</div>
													<div>{ this.state.vehicle___props['vehicle_conditions___registration_month'] + '/' + this.state.vehicle___props['vehicle_conditions___registration_year'] }</div>
												</div>
												<div>
													<div></div>
													<div>Targa</div>
													<div>
														{ (this.state.vehicle___props['vehicle_registration___plate']) ? <>
															{ this.state.vehicle___props['vehicle_registration___plate'] }
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Telaio</div>
													<div>
														{ (this.state.vehicle___props['vehicle_registration___vin']) ? <>
															{ this.state.vehicle___props['vehicle_registration___vin'] }
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Contabilità</div><div></div></div>
												<div>
													<div></div>
													<div>Acquisto - Prezzo</div>
													<div>
														{(this.state.vehicle___props['vehicle_accounting___price_bought']) ?  <>
															{ this.funcs___numbers.format___price(this.state.vehicle___props['vehicle_accounting___price_bought'], '€')}
															<span style={{paddingLeft:'15pt'}}>iva { this.state.vehicle___props['vehicle_accounting___price_bought_vat'] }%</span>
															<span style={{paddingLeft:'15pt'}}>
																{(() => {
																	switch (this.state.vehicle___props['vehicle_accounting___price_bought_vat_type'] as type___vehicle___price_vat_type) {
																		case 'included': return <>INCLUSA</>; break;
																		case 'exposed': return <>ESPOSTA</>; break;
																		case 'margin': return <>A MARGINE</>; break;
																		case 'partial': return <>PARZIALE</>; break;
																	}
																})()}
															</span>
														</> : <>-</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>Vendita - Prezzo</div>
													<div>
														{(this.state.vehicle___props['vehicle_accounting___price_sell']) ?  <>
															{ this.funcs___numbers.format___price(this.state.vehicle___props['vehicle_accounting___price_sell'], '€')}
															<span style={{paddingLeft:'15pt'}}>iva { this.state.vehicle___props['vehicle_accounting___price_sell_vat'] }%</span>
															<span style={{paddingLeft:'15pt'}}>
																{(() => {
																	switch (this.state.vehicle___props['vehicle_accounting___price_sell_vat_type'] as type___vehicle___price_vat_type) {
																		case 'included': return <>INCLUSA</>; break;
																		case 'exposed': return <>ESPOSTA</>; break;
																		case 'margin': return <>A MARGINE</>; break;
																		case 'partial': return <>PARZIALE</>; break;
																	}
																})()}
															</span>
														</> : <>-</> }
													</div>
												</div>
											</div>
										</>; break;
										case 'specs': return <>
											<div className="container---table table-vehicle---registry is-card">
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Alimentazione</div><div></div></div>
												<div>
													<div></div>
													<div>Carburante</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___engine_fuel_type']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('engine_fuel_type', this.state.vehicle___props['vehicle_specs___engine_fuel_type'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Classe Consumi</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___emission_class']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('emission_class', this.state.vehicle___props['vehicle_specs___emission_class'])}
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Motore</div><div></div></div>
												<div>
													<div></div>
													<div>Modello</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___engine_name']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('engine_name', this.state.vehicle___props['vehicle_specs___engine_name'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Displacement Cilindri</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___engine_cylinders_displacement']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('engine_cylinders_displacement', this.state.vehicle___props['vehicle_specs___engine_cylinders_displacement'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Numero Cilindri</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___engine_cylinders_number']) ?  <>
															{ this.state.vehicle___props['vehicle_specs___engine_cylinders_number']}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div> Cilindrata</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___engine_capacity']) ?  <>
															{ this.state.vehicle___props['vehicle_specs___engine_capacity'] + ' cc'}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Potenza</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___engine_power_kw']) ?  <>
															{ this.state.vehicle___props['vehicle_specs___engine_power_kw'] + ' kw (' + this.funcs___numbers.convert___power(this.state.vehicle___props['vehicle_specs___engine_power_kw'], 'hp') + ' cv)' }
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Cambio</div><div></div></div>
												<div>
													<div></div>
													<div>Tipo</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___gearbox_type']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('gearbox_type', this.state.vehicle___props['vehicle_specs___gearbox_type'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>N° Rapporti</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___gearbox_gears']) ?  <>
															{ this.state.vehicle___props['vehicle_specs___gearbox_gears'] + ' Rapporti'}
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Trasmissione</div><div></div></div>
												<div>
													<div></div>
													<div>Trazione</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___transmission_drive']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('transmission_drive', this.state.vehicle___props['vehicle_specs___transmission_drive'])}
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Impianto Frenante</div><div></div></div>
												<div>
													<div></div>
													<div>Freni Anteriori</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___brakes_front_type']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('brakes_type', this.state.vehicle___props['vehicle_specs___brakes_front_type'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Freni Posteriori</div>
													<div>
														{(this.state.vehicle___props['vehicle_specs___brakes_rear_type']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('brakes_type', this.state.vehicle___props['vehicle_specs___brakes_rear_type'])}
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Esterni</div><div></div></div>
												<div>
													<div></div>
													<div>Carrozzeria</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___body_type']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('body_type', this.state.vehicle___props['vehicle_config___body_type'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Numero Porte</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___body_doors_number']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('body_doors_number', this.state.vehicle___props['vehicle_config___body_doors_number'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Vernice</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___paint_type']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('paint_type', this.state.vehicle___props['vehicle_config___paint_type'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Colore</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___paint_color']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('paint_color', this.state.vehicle___props['vehicle_config___paint_color'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Ruote (Cerchi)</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___wheel_material']) ?  <>
															{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('wheel_material', this.state.vehicle___props['vehicle_config___wheel_material'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Gomme</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___wheel_tyres']) ?  <>
															{ this.state.vehicle___props['vehicle_config___wheel_tyres'] }
														</> : <>-</> }
													</div>
												</div>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Interni</div><div></div></div>
												<div>
													<div></div>
													<div>Materiale</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___interior_material']) ?  <>
														{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('interior_material', this.state.vehicle___props['vehicle_config___interior_material'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Colore</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___interior_color']) ?  <>
														{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('interior_color', this.state.vehicle___props['vehicle_config___interior_color'])}
														</> : <>-</> }
													</div>
												</div>
												<div>
													<div></div>
													<div>Modanature</div>
													<div>
														{(this.state.vehicle___props['vehicle_config___interior_details']) ?  <>
														{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('interior_details', this.state.vehicle___props['vehicle_config___interior_details'])}
														</> : <>-</> }
													</div>
												</div>



{/*

														@Prop() vehicle_config___optionals: string[];

														@Prop() vehicle_efficiency___engine: type___vehicle___condition;
														@Prop() vehicle_efficiency___power_supply: type___vehicle___condition;
														@Prop() vehicle_efficiency___exhaust:  type___vehicle___condition;
														@Prop() vehicle_efficiency___exhaust_emissions: type___vehicle___condition;
														@Prop() vehicle_efficiency___leaks: type___vehicle___condition;
														@Prop() vehicle_efficiency___engine_cooling: type___vehicle___condition;
														@Prop() vehicle_efficiency___gearbox: type___vehicle___condition;
														@Prop() vehicle_efficiency___clutch: type___vehicle___condition;
														@Prop() vehicle_efficiency___brakes: type___vehicle___condition;
														@Prop() vehicle_efficiency___steering: type___vehicle___condition;
														@Prop() vehicle_efficiency___suspensions: type___vehicle___condition;
														@Prop() vehicle_efficiency___wheels: type___vehicle___condition;
														@Prop() vehicle_efficiency___noise: type___vehicle___condition;
														@Prop() vehicle_efficiency___battery: type___vehicle___condition;
														@Prop() vehicle_efficiency___engine_starter: type___vehicle___condition;
														@Prop() vehicle_efficiency___lights: type___vehicle___condition;
														@Prop() vehicle_efficiency___windows: type___vehicle___condition;
														@Prop() vehicle_efficiency___windows_wipers: type___vehicle___condition;
														@Prop() vehicle_efficiency___warnings: type___vehicle___condition;
														@Prop() vehicle_efficiency___air_conditioning: type___vehicle___condition;
														@Prop() vehicle_efficiency___mirrors: type___vehicle___condition;
														@Prop() vehicle_efficiency___seatbelts: type___vehicle___condition;
														@Prop() vehicle_efficiency___interior: type___vehicle___condition;
														@Prop() vehicle_efficiency___interior_seats: type___vehicle___condition;
														@Prop() vehicle_efficiency___dashboard: type___vehicle___condition;
														@Prop() vehicle_efficiency___body: type___vehicle___condition[];

														@Prop() vehicle_info___description: string;


	@Prop() vehicle_creation_dealer: Types.ObjectId; 
	@Prop() vehicle_creation_user: Types.ObjectId;
	@Prop() vehicle_creation_datetime: Date;
	
	@Prop() vehicle_status_sold: boolean;
	@Prop() vehicle_status_sold_datetime: Date | null;
	@Prop() vehicle_status_sold_user: Types.ObjectId | null;
	@Prop() vehicle_status_sold_lead: Types.ObjectId | null;
	@Prop() vehicle_status_sold_partner: Types.ObjectId | null;

	@Prop() vehicle_visibility: type___vehicle___visibility;

	@Prop() vehicle_imported___subito_it: string;
	@Prop() vehicle_imported___subito_it___url: string;




*/}

											</div>






















										</>; break;
										case 'status': return <>
											







											
										</>; break;
										case 'leads': return <>
											<div className="is-card container---table table-vehicle-single---leads">
												<div>
													<div></div>
													<div>
														<span>Data e Ora</span>
														{(this.state.vehicle___leads___list___order['param'] === 'partner_name') ? <>
															{(this.state.vehicle___leads___list___order['direction'] === 1) ? <>
																<i className="fas fa-sort-up" onClick={() => { this.handle___leads___sortBy('createdAt'); }}></i>
															</> : <>
																<i className="fas fa-sort-down" onClick={() => { this.handle___leads___sortBy('createdAt'); }}></i>
															</>}
														</> : <>
															<i className="fas fa-sort" onClick={() => { this.handle___leads___sortBy('createdAt'); }}></i>
														</>}
													</div>
													<div>
														<span>Cliente</span>
														<span>•</span>
													</div>
													<div>
														<span>Stato</span>
														<span>•</span>
													</div>
												</div>
												{(this.state.vehicle___leads___list.length > 0) ? <>
													{(this.state.GUI___page___is_loading___section === true) ? <>
														<ComSpinnerComponent/>
													</> : <>
														{this.state.vehicle___leads___list.map((___l: any, ___id: number) => (
															<div>
																<div>
																	<Link to={ '/leads/view/' + ___l['lead_id'] } data-title="Visualizza">
																		<i className="fas fa-eye"></i>
																	</Link>
																</div>
																<div>
																	{ this.funcs___datetime.datetime___get___date(___l['lead_creation_datetime']) }
																	<span> - </span>
																	{ this.funcs___datetime.datetime___get___time(___l['lead_creation_datetime']) }
																</div>
																<div>
																	<Link to={ '/partners/view/' + ___l['lead_partner']['_id'] } data-title="Vai all'anagrafica">
																		{(___l['lead_partner']['partner_type'] === 'p') ? <>
																			<span>{ ___l['lead_partner']['partner_name'] + ' ' + ___l['lead_partner']['partner_surname'] }</span>
																		</> : <>
																			<span>{ ___l['lead_partner']['partner_name_business'] }</span> 
																			<span style={{opacity:'0.5'}}> - { ___l['lead_partner']['partner_name'] + ' ' + ___l['lead_partner']['partner_surname'] }</span>
																		</>}
																	</Link>
																</div>
																<div>
																	{(() => {
																		switch (___l['lead_status'] as type___lead___status) {
																			case 'new': return <><b>NUOVO</b></>; break;
																			case 'working': return <><b style={{color:'var(--color-warning)'}}>IN LAVORAZIONE</b></>; break;
																			case 'ready': return <><b>PRONTO</b></>; break;
																			case 'sold': return <><b style={{color:'var(--color-success)'}}>VENDUTO</b></>; break;
																			case 'archived': return <><b style={{color:'var(--color-danger)'}}>ARCHIVIATO</b></>; break;
																		}
																	})()}
																</div>
															</div>
														))}
													</>}
												</> : <>
													<ComResultsEmptyComponent text="nessun Lead trovato"/>
												</>}
											</div>
										</>; break;
										case 'documents': return <>
											<div className="is-card">
												{(() => {
													switch (this.props.props___licence['licence_active___cloudDocs']) {
														case true: return <>
															<br />
															<div className="page-container---bottom-bar---buttons is-centered">
																<button type="button" className="is-card is-standard no-shadow" onClick={ this.handle___file_upload }>
																	<i className="fas fa-upload"></i>
																	<span className="is-full-width">Carica un documento nel cloud</span>
																</button>
															</div>
															<br />
															{(this.state.vehicle___documents.length > 0) ? <>
																<div className="container---table table-partners---documents">
																	<div>
																		<div></div>
																		<div>
																			<span>Data e Ora</span>
																			<span>•</span>
																		</div>
																		<div>
																			<span>Titolo</span>
																			<span>•</span>
																		</div>
																		<div>
																			<span>Dimensione</span>
																			<span>•</span>
																		</div>
																		<div>
																			<span>Tipo</span>
																			<span>•</span>
																		</div>
																	</div>
																	{this.state.vehicle___documents.map((___d: any, ___d_i: number) => (
																		<div key={ ___d_i }>
																			<div>
																				<Link to={ lib_names.cdn.endpoint + ___d['document_reference'] } data-title="Visualizza">
																					<i className="fas fa-eye"></i>
																				</Link>
																				<i className="fas fa-trash" onClick={()=>{ this.handle___documents___delete(___d, ___d_i); }} data-title="Elimina"></i>
																			</div>
																			<div>{ this.funcs___datetime.datetime___get___date(___d['document_createdAt']) + ' alle ' + this.funcs___datetime.datetime___get___time(___d['document_createdAt']) }</div>
																			<div><b>{ ___d['document_name'] }</b></div>
																			<div>{ this.funcs___numbers.format___bytesize(___d['document_size']) } </div>
																			<div>File <span style={{textTransform:'uppercase',fontWeight:'bold'}}>{ ___d['document_mime'] }</span></div>
																		</div>
																	))}
																</div>
															</> : <>
																<ComResultsEmptyComponent text="nessun documento per questo cliente/fornitore"/>
															</>}
														</>; break;
														case false: return <>
															<ComLicenceInactiveComponent licence_needed="cloud_docs"/>
														</>; break;
														case null: return <>
															<ComSpinnerComponent/>
														</>; break;
													}
												})()}
											</div>
										</>;break;	
									}
								})()}
							</div>
						</section>
					</div>
				</> : <>
					<Com404Component/>
				</>}
			</> : <>
				<ComSpinnerComponent/>
			</>}
		</>;
	}

//#endregion

}




