//	L I B S

import { lib_defaults } from '../libs/lib.defaults';
import { lib_names } from '../libs/lib.names';
import { type___api___response } from '../types/types.api-response';

//	T Y P E S

import { type___activity___type, type___contract___type, type___dealer_imap___account, type___dealer_setting___property, type___document___type, type___lead___interest___to, type___lead___status, type___partner___address, type___partner___address___born, type___partner___einvoice___type, type___partner___type, type___request___contract_additionals, type___request___order, type___user___address, type___user___role, type___vehicle___type, type___vehicle___visibility } from '../types/types.types';

//	E X P O R T

export class service_RestApiService
{

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							W H O A M I

	private load___whoami()
	{
		const ___whoami_token: string | null = <string>localStorage.getItem(lib_names.storage.user___logintoken) ?? null;
		if (___whoami_token) { this._HEADERS['Authorization'] = 'Bearer ' + ___whoami_token as string; }
	}

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___action: string, ___target: string, ___to_post: any | {} = {}) : Promise<any>
	{
		this.load___whoami();
		const ___s_route: string = lib_names.endpoints.server_api + ___action + '/' + ___target;
		try {
			const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
			if (___s_response.ok) {
				try {
					const ___server_response: any = await ___s_response.json();
					return (___server_response !== null && ___server_response !== undefined) ? ___server_response : null;
				} catch (___e: any) {
					return null;
				}
			} else {
				return null;
			}
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return null;
		}
	}

//#endregion

//#region 																							A C T I V I T I E S

	async activities___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0, ___order: string = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order ?? 'standard' };
		return await this.return_server_response('activities', 'read_multi', ___to_post)
	}

	async activities___read___single(___activity_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'activity_id': ___activity_id };
		return await this.return_server_response('activities', 'read_single', ___to_post)
	}

	async activities___submit(___a_id: string | null, ___a_lead: string, ___a_type: type___activity___type, ___a_datetime_from: string, ___a_datetime_to: string, ___a_notes: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'a_id': ___a_id, 'a_lead': ___a_lead, 'a_type': ___a_type, 'a_datetime_from': ___a_datetime_from, 'a_datetime_to': ___a_datetime_to, 'a_notes': ___a_notes };
		return await this.return_server_response('activities', 'submit', ___to_post)
	}

//#endregion

//#region 																							A N A L Y T I C S

	//	saltato inizio

	async analytics___year(___year: number) : Promise<any[]>
	{
		const ___to_post: any = { 'year': ___year };
		return await this.return_server_response('analytics', 'year', ___to_post)
	}

	//	saltato fine

//#endregion

//#region 																							C A L E N D A R

	async calendar___read___from_month(___month: number, ___year: number) : Promise<type___api___response>
	{
		const ___to_post: any = { 'month': ___month, 'year': ___year };
		return await this.return_server_response('calendar', 'read_from_month', ___to_post)
	}

//#endregion

//#region 																							C A L E N D A R

	async cloud___read___status() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('cloud', 'read_status', ___to_post)
	}

//#endregion

//#region 																							C O N T R A C T S

	async contracts___read___single(___contract_type: type___contract___type) : Promise<type___api___response>
	{
		const ___to_post: any = { 'contract_type': ___contract_type };
		return await this.return_server_response('contracts', 'read_single', ___to_post)
	}

	async contracts___submit(___contract_type: type___contract___type, ___contract_text: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'contract_type': ___contract_type, contract_text: ___contract_text };
		return await this.return_server_response('contracts', 'submit', ___to_post)
	}

//#endregion

//#region 																							D E A L E R S
	
	async dealers___read___single() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('dealers', 'read_single', ___to__post);
	}

	async dealers___read___connector_credentials() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('dealers', 'read_connector_credentials', ___to__post);
	}

	async dealers___read___importer_credentials___vehicles() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('dealers', 'read_importer_credentials_vehicles', ___to__post);
	}

//#endregion

//#region 																							D E A L E R S   I M A P S

	async dealers_imaps___read___multi() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('dealers_imaps', 'read_multi', ___to__post);
	}

	async dealers_imaps___read___single(___a_id: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'imap_account_id': ___a_id };
		return await this.return_server_response('dealers_imaps', 'read_single', ___to__post);
	}

	async dealers_imaps___submit(___a_id: string | null, ___a_structure: type___dealer_imap___account) : Promise<type___api___response>
	{
		const ___to__post: any = { 'imap_account_id': ___a_id, 'imap_account_structure': ___a_structure };
		return await this.return_server_response('dealers_imaps', 'submit', ___to__post);
	}

//#endregion

//#region 																							D E A L E R S   L I C E N C E S

	async dealers_licences___read___single() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('dealers_licences', 'read_single', ___to__post);
	}

//#endregion

//#region 																							D E A L E R S   S E T T I N G S

	async dealers___change_settings(___s_collection: type___dealer_setting___property[]) : Promise<type___api___response>
	{
		const ___to__post: any = { 'settings_collection': ___s_collection };
		return await this.return_server_response('dealers', 'change_settings', ___to__post);
	}

//#endregion

//#region 																							D O C U M E N T S

	async documents___read___multi(___search_target: type___document___type, ___search_param: string | null, ___order: string = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'order': ___order ?? 'standard' };
		return await this.return_server_response('documents', 'read_multi', ___to_post)
	}

	async documents___submit(___d___title: string, ___d___b64: string, ___d___filename: string, ___d___size: number, ___d_target: type___document___type, ___d_target_param: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'd_title': ___d___title, 'd_base64': ___d___b64, 'd_filename': ___d___filename, 'd_size': ___d___size, 'd_target': ___d_target, 'd_target_param': ___d_target_param };
		return await this.return_server_response('documents', 'submit', ___to__post);
	}

	async documents___delete(___document_id: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'document_id': ___document_id }
		return await this.return_server_response('documents', 'delete', ___to__post);
	}

//#endregion

//#region 																							L E A D S

	async leads___count() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('leads', 'count', ___to__post);
	}

	async leads___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0, ___order: type___request___order) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order ?? 'standard' };
		return await this.return_server_response('leads', 'read_multi', ___to_post)
	}

	async leads___read__single(lead___id: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': lead___id }
		return await this.return_server_response('leads', 'read_single', ___to__post);
	}

	async leads___delete(___lead_id: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': ___lead_id }
		return await this.return_server_response('leads', 'delete', ___to__post);
	}

	async leads___submit(___lead_id: string | null, ___partner_id: string, ___interest_to: type___lead___interest___to, ___years: [number | null, number | null], ___prices: [number | null, number | null], ___i_custom: string, ___brands: string[], ___models: string[], ___notes: string, ___e_exchange: string, ___e_exchange_item: string, ___e_exchange_price: number | null, ___m_source: string, ___m_source_additional: string, ___m_source_notes: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': ___lead_id, 'partner_id': ___partner_id, 'i_to': ___interest_to, 'i_years' : ___years, 'i_prices': ___prices, 'i_custom': ___i_custom, 'i_brands': ___brands, 'i_models': ___models, 'i_notes': ___notes, 'e_exchange': ___e_exchange, 'e_exchange_item': ___e_exchange_item, 'e_exchange_price': ___e_exchange_price, 'm_source': ___m_source, 'm_source_additional': ___m_source_additional, 'm_source_notes': ___m_source_notes }
		return await this.return_server_response('leads', 'submit', ___to__post);
	}

	async leads___submit___contract(___lead_id: string, ___lead_contract_fields: string[]) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': ___lead_id, 'lead_contract_fields': ___lead_contract_fields }
		return await this.return_server_response('leads', 'submit_contract', ___to__post);
	}

	async leads___submit___new_partner(___p_type: type___partner___type, ___p_name_business: string | null, ___p_name: string, ___p_surname: string, ___p_contact_phone: string, ___p_contact_email: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'p_type': ___p_type, 'p_name_business': ___p_name_business, 'p_name': ___p_name, 'p_surname': ___p_surname, 'p_contact_phone': ___p_contact_phone, 'p_contact_email': ___p_contact_email }
		return await this.return_server_response('leads', 'submit_new_partner', ___to__post);
	}

	async leads___update___status(___lead_id: string, ___lead_status_new: type___lead___status | 'unsold', ___lead_additionals: type___request___contract_additionals | null) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': ___lead_id, 'target_status': ___lead_status_new }
		return await this.return_server_response('leads', 'update_status', ___to__post);
	}

	async leads___update___status_viewed(___lead_id: string, ___lead_is_viewed: boolean = true) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': ___lead_id, 'is_viewed': ___lead_is_viewed }
		return await this.return_server_response('leads', 'update_status_viewed', ___to__post);
	}

	async leads___match___vehicle(___lead_id: string, ___vehicle_id: string | null) : Promise<type___api___response>
	{
		const ___to__post: any = { 'lead_id': ___lead_id, 'vehicle_id': ___vehicle_id }
		return await this.return_server_response('leads', 'match_vehicle', ___to__post);
	}

//#endregion

//#region 																							P A R T N E R S

	async partners___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0, ___order: type___request___order = lib_defaults.types.type___request___order) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order };
		return await this.return_server_response('partners', 'read_multi', ___to_post);
	}

	async partners___read___single(___partner_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'partner_id': ___partner_id };
		return await this.return_server_response('partners', 'read_single', ___to_post);
	}

	async partners___submit(___p_id: string | null, ___p_type: type___partner___type | '', ___p_is_c: boolean, ___p_is_b: boolean, ___p_name: string, ___p_name_b: string, ___p_surname: string, ___p_emails: [string, string], ___p_phones: [string, string, string], ___p_born_date: string | null, ___p_born_address: type___partner___address___born, ___p_reg_cf: string, ___p_reg_vat: string, ___p_e_inv_type: type___partner___einvoice___type, ___p_e_inv_sdi: string, ___p_address: type___partner___address, ___p_address_b: type___partner___address, ___p_add_notes: string, ___p_website: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'p_id': ___p_id, 'p_type': ___p_type, 'p_is_c': ___p_is_c, 'p_is_b': ___p_is_b, 'p_name': ___p_name, 'p_name_b': ___p_name_b, 'p_surname': ___p_surname, 'p_emails': ___p_emails, 'p_phones': ___p_phones, 'p_born_date': ___p_born_date, 'p_born_address': ___p_born_address, 'p_reg_cf': ___p_reg_cf, 'p_reg_vat': ___p_reg_vat, 'p_e_inv_type': ___p_e_inv_type, 'p_e_inv_sdi': ___p_e_inv_sdi, 'p_address': ___p_address, 'p_address_b': ___p_address_b, 'p_notes': ___p_add_notes, 'p_website': ___p_website };
		return await this.return_server_response('partners', 'submit', ___to__post);
	}

	async partner___delete(___p_id : string) : Promise<type___api___response>
	{
		const ___to__post : any = { 'partner_id': ___p_id };
		return this.return_server_response('partners', 'delete', ___to__post);
	}

//#endregion

//#region 																							S Y N C   L E A D S

	async sync_leads___sync() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('sync/leads', 'sync', ___to__post);
	}

//#endregion

//#region 																							S Y N C   V E H I C L E S

	async sync_vehicles___from___subito_it() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('sync/vehicles', 'from/subitoit', ___to__post);
	}

	async sync_vehicles___from___subito_it___single(___vehicle_id: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'vehicle_id': ___vehicle_id};
		return await this.return_server_response('sync/vehicles', 'from/subitoit/single', ___to__post);
	}

	async importer_vehicles___from___subito_it___resync_media(___vehicle_id: string, ____subito_url: string) : Promise<type___api___response>
	{
		const ___to__post: any = { 'vehicle_id': ___vehicle_id, 'subito_url': ____subito_url};
		return await this.return_server_response('sync/vehicles', 'from/subitoit/media', ___to__post);
	}

	async sync_vehicles___reset() : Promise<type___api___response>
	{
		const ___to__post: any = { };
		return await this.return_server_response('sync/vehicles', 'reset', ___to__post);
	}

//#endregion

//#region 																							U S E R S

	async users___read___multi(___search_target: string, ___search_param: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('users', 'read_multi', ___to_post);
	}

	async users___read___single(___search_target: 'username' | 'id' | 'me', ___search_param: string | null = null) : Promise<any>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('users', 'read_single', ___to_post);
	}

	async users___submit(___u_id: string | null, ___u_role: type___user___role,	___u_username: string, ___u_name: string, ___u_surname: string, ___u_r_cf: string, ___u_c_mail: string, ___u_c_phone: string, ___u_address: type___user___address) : Promise<type___api___response>
	{
		const ___to_post: any = { 'u_id': ___u_id, 'u_role': ___u_role, 'u_username': ___u_username, 'u_name': ___u_name, 'u_surname': ___u_surname, 'u_r_cf': ___u_r_cf, 'u_c_mail': ___u_c_mail, 'u_c_phone': ___u_c_phone, 'u_address': ___u_address };
		return await this.return_server_response('users', 'submit', ___to_post);
	}

	async users___delete(___u_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'u_id': ___u_id };
		return await this.return_server_response('users', 'delete', ___to_post);
	}

	async users___edit___password(___p_old: string, ___p_new: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'p_old': ___p_old, 'p_new': ___p_new };
		return await this.return_server_response('users', 'edit_password', ___to_post);
	}

//#endregion

//#region 																							V E H I C L E S

	async vehicles___read___multi(___search_target: string, ___search_param: string, ___last_index: number = 0, ___order: type___request___order) : Promise<type___api___response>
	{
		const ___to_post: any = { search_target: ___search_target, search_param: ___search_param, last_index: ___last_index, order: ___order ?? 'standard' };
		return await this.return_server_response('vehicles', 'read_multi', ___to_post);
	}

	async vehicles___read___single(___vehicle_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'vehicle_id': ___vehicle_id };
		return await this.return_server_response('vehicles', 'read_single', ___to_post);
	}

	async vehicles___submit(___v_id: string | null, ___v_category: type___vehicle___type, ___v_gallery: string[], ___v_360: string | null, ___v_video: string, ___v_object: any, ___v_visibility: type___vehicle___visibility) : Promise<type___api___response>
	{
		const ___to_post: any = { 'v_id': ___v_id, 'v_category': ___v_category, 'v_gallery': ___v_gallery, 'v_360': ___v_360, 'v_video': ___v_video, 'v_object': ___v_object, 'v_visibility': ___v_visibility};
		return await this.return_server_response('vehicles', 'submit', ___to_post);
	}

	async vehicles___delete(___vehicle_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'vehicle_id': ___vehicle_id };
		return await this.return_server_response('vehicles', 'delete', ___to_post);
	}

	async vehicles___update_sell(___vehicle_id: string, ___action: 'sell' | 'unsell') : Promise<type___api___response>
	{
		const ___to_post: any = { 'vehicle_id': ___vehicle_id, 'action': ___action };
		return await this.return_server_response('vehicles', 'update_sell', ___to_post);
	}

	async vehicles___update___visibility(___vehicle_id: string, ___visibility: type___vehicle___visibility) : Promise<type___api___response>
	{
		const ___to_post: any = { 'vehicle_id': ___vehicle_id, 'visibility': ___visibility };
		return await this.return_server_response('vehicles', 'update_visibility', ___to_post);
	}

//#endregion

//#region 																							V E H I C L E S   B R A N D S

	async vehicles_brands___read___multi(___search_target: string, ___search_param: string | null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('vehicles_brands', 'read_multi', ___to_post);
	}

	async vehicles_brands___read___single(___v_brands_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'v_brand_id': ___v_brands_id };
		return await this.return_server_response('vehicles_brands', 'read_single', ___to_post);
	}

//#endregion

//#region 																							V E H I C L E S   M O D E L S

	async vehicles_models__read___multi(___category: type___vehicle___type, ___brand: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'category': ___category, 'brand': ___brand };
		return await this.return_server_response('vehicles_models', 'read_multi', ___to_post);
	}

	async vehicles_models___read___single(___v_model_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'v_model_id': ___v_model_id };
		return await this.return_server_response('vehicles_models', 'read_single', ___to_post);
	}

//#endregion
















	//	refactoring inizia da qui
























































//#region 																							V E H I C L E S   M O D E L S -> deprecated

	async vehicles_models___read___multi___properties(___property: string) : Promise<any>
	{
		const ___to_post: any = { 'property': ___property };
		return await this.return_server_response('vehicles_models', 'read_multi_properties', ___to_post);
	}

	async vehicles_models__read___multi___brands(___category: type___vehicle___type) : Promise<any>
	{
		const ___to_post: any = { 'category': ___category };
		return await this.return_server_response('vehicles_models', 'read_multi_brands', ___to_post);
	}

	async vehicles_models__read___multi___models(___category: type___vehicle___type, ___brand: string) : Promise<any>
	{
		const ___to_post: any = { 'category': ___category, 'brand': ___brand };
		return await this.return_server_response('vehicles_models', 'read_multi_models', ___to_post);
	}

	async vehicles_models__read___multi___versions(___category: type___vehicle___type, ___brand: string, ___model: string) : Promise<any>
	{
		const ___to_post: any = { 'category': ___category, 'brand': ___brand, 'model': ___model };
		return await this.return_server_response('vehicles_models', 'read_multi_versions', ___to_post);
	}

	async vehicles_models__read___multi___variants(___category: type___vehicle___type, ___brand: string, ___model: string, ___version: string) : Promise<any>
	{
		const ___to_post: any = { 'category': ___category, 'brand': ___brand, 'model': ___model, 'version': ___version };
		return await this.return_server_response('vehicles_models', 'read_multi_variants', ___to_post);
	}

	async vehicles_models__read___single___variants(___variant: string) : Promise<any>
	{
		const ___to_post: any = { 'variant_id': ___variant };
		return await this.return_server_response('vehicles_models', 'read_single_variants', ___to_post);
	}

	async vehicles_models___read___multi___search(___year_from: number, ___year_to: number, ___search_param: string, ___skip_trims: number[] | string[]) : Promise<any>
	{
		const ___to_post: any = { 'year_from': ___year_from, 'year_to': ___year_to, 'search_param': ___search_param, 'skip_trims': ___skip_trims };
		return await this.return_server_response('vehicles_models', 'read_multi_search', ___to_post);
	}

//#endregion

}