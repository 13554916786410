import { type___modals___controller } from "../../types/types.modals"
import { type___document___type } from "../../types/types.types"

//	P R O P S

export interface props___MdVehicleResyncMediaModal extends type___modals___controller {
	
	target_vehicle_id: string,

}

//	S T A T E

export interface state___MdVehicleResyncMediaModal {

	field___url: string

	GUI___modal___is_uploading: boolean,

}

//	S T A T E   D E F A U L T 

export const state___MdVehicleResyncMediaModal___default: state___MdVehicleResyncMediaModal = {

	field___url: '',

	GUI___modal___is_uploading: false,

}