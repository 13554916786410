import React from 'react';

//	S T Y L E S

import './md-vehicle-resync-media.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___document___type } from '../../types/types.types';

//	S T A T E S

import { props___MdVehicleResyncMediaModal, state___MdVehicleResyncMediaModal, state___MdVehicleResyncMediaModal___default } from './md-vehicle-resync-media.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class MdVehicleResyncMediaModal extends React.Component<props___MdVehicleResyncMediaModal, state___MdVehicleResyncMediaModal> {

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdVehicleResyncMediaModal,
	) {
		super(props);
		this.state = state___MdVehicleResyncMediaModal___default
	}

//#endregion

//#region 																							S U B M I T 

	submit___resync = () => {
		
		this.setState({
			GUI___modal___is_uploading: true,
		}, async () => {
			const ___sync___submitted: type___api___response = await this._API.importer_vehicles___from___subito_it___resync_media(this.props.target_vehicle_id, this.state.field___url);
			console.log('___sync___submitted', ___sync___submitted)
			this.setState({
				GUI___modal___is_uploading: false,
			}, () => {
				this.props.event___onDismiss(true);
			});
		});

	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{

	}

//#endregion

//#region 																							R E N D E R

	render() {
		return (<>
			<div className="resync-media---opacizer" onClick={() => { this.props.event___onDismiss(null); }}></div>
			<div className="resync-media---container is-card">
				{(this.state.GUI___modal___is_uploading === false) ? <>

					<br />

					<h3>Sincronizza Media</h3>

					<br />
					<br />

					<p>
						Sincronizzando manualmente i media di questo veicolo perderai i media attuali, usa questa funzione solo se sei sicuro di quello 
						che stai facendo, <b>l'operazione è irreversibile</b>. I media che stai per sincronizzare potrebbero presentare dei watermark.
					</p>

					<br />
					<br />

					<span className="input---nb-label is-required">url all'annuncio del veicolo</span>
					<section className="input---nb-outlined---flexy">
						<input type="text" value={ this.state.field___url } className="input---nb-outlined" placeholder="https://www.subito.it/........." onInput={(___e: any) => { this.setState({ field___url: ___e.target.value })}}/>
					</section>

					<br />
					<br />

					<div className="page-container---bottom-bar---buttons is-centered">
						<button type="button" className="is-card no-shadow" onClick={() => { this.props.event___onDismiss(null); }}>
							<i className="fas fa-times"></i>
							<span>Chiudi</span>
						</button>
						{(this.state.field___url.length > 0) ? <>
							<button type="button" className="is-card is-submit no-shadow" onClick={ this.submit___resync }>
								<i className="fas fa-file-import"></i>
								<span className="is-full-width">Continua</span>
							</button>
						</> : <></>}
					</div>

				</> : <>
					<ComSpinnerComponent/>
				</>}
			</div>
		</>);
	}

//#endregion

}